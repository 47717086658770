import React, { Component } from 'react';
import Grid from '@mui/material/Grid';
import Button from '@mui/material/Button';
import Box from '@mui/material/Box';
import Card from '@mui/material/Card';
import CardActions from '@mui/material/CardActions';
import CardContent from '@mui/material/CardContent';
import CardHeader from '@mui/material/CardHeader';
import LinearProgress from '@mui/material/LinearProgress';
import moment from 'moment';

import TimerIcon from '@mui/icons-material/Timer';
import ProductionQuantityLimitsIcon from '@mui/icons-material/ProductionQuantityLimits';
import { NumericFormat } from 'react-number-format';


import './App.css';
import { Container } from '@mui/material';

export default class Lottery extends Component {


	saySomething = (something) => {
		console.log(something);
	}

	render() {
    const lottos = [
      {
        title: 'The First Lotto',
        status: 'FINISHED',
        price: '10000',
        description: 'The first lottery',
        closeType: 'soldout',
        closeVal1: '5000',
        closeVal2: '5000',
        timeDiff: '',
        sold: '5000'
      },
      {
        title: 'Cactus Cranker Lotto',
        status: 'OPEN',
        price: '15000',
        description: 'The second lottery',
        closeType: 'soldout',
        closeVal1: '570',
        closeVal2: '10000',
        timeDiff: '',
        sold: '570'
      },
      {
        title: 'Countdown to Dex',
        status: 'OPEN',
        price: '30000',
        description: 'The third lottery',
        closeType: 'time',
        closeVal1: '1702777756',
        closeVal2: '1702795756',
        timeDiff: '',
        sold: '120'
      },
      {
        title: 'Cactus Rockets',
        status: 'OPEN',
        price: '30000',
        description: 'The 4th lottery',
        closeType: 'time',
        closeVal1: '1702777756',
        closeVal2: '1702879756',
        timeDiff: '',
        sold: '40'
      },
      {
        title: 'Million Mega Cactpot',
        status: 'OPEN',
        price: '1000000',
        description: 'The final lottery',
        closeType: 'soldout',
        closeVal1: '2',
        closeVal2: '10',
        timeDiff: '',
        sold: '2'
      },
      {
        title: 'Future Lottery',
        status: 'SOON',
        price: '25000',
        description: 'The final lottery',
        closeType: 'soldout',
        closeVal1: '0',
        closeVal2: '50',
        timeDiff: '',
        sold: '0'
      },
    ];

    let i = 0;

    while (i < lottos.length) {

      if (lottos[i].closeType === "time") {
        const startDate = moment();
        const timeEnd = moment.unix(lottos[i].closeVal2);
        const diff = timeEnd.diff(startDate);
        const diffDuration = moment.duration(diff);
        lottos[i].timeDiff = diffDuration.days() + ' days ' + diffDuration.hours() + ' hrs ' + diffDuration.minutes() + ' mins ';
      }

      i++;
    }



		return(

	    <Grid container spacing={3} alignItems="center" justifyContent="center" className='lotto-cont'>
          {lottos.map((lotto) => (
            <Grid
              item
              key={lotto.title}
              xs={12}
              sm={6}
              md={3}
              alignSelf='baseline'
              display='flex'
              justifyContent='center'
            >
              <Card className={'lotto-item lotto-status-' + lotto.status}>
                <CardHeader
                  className={'lotto-head'}
                  title={lotto.title}
                  subheader={lotto.status}
                  titleTypographyProps={{ align: 'center' }}
                  subheaderTypographyProps={{
                    align: 'center',
                    className: 'lotto-status'
                  }}
                />
                <CardContent>
                  <Container
                      className="item-box-cont"
                      sx={{
                      display: 'flex',
                      justifyContent: 'space-between',
                      columnGap: '20px'
                    }}
                    >

                    <Card className="item-box">
                      <CardHeader
                        title='Ticket Price'
                        titleTypographyProps={{ className: 'item-label'}}
                        sx={{
                          backgroundColor: '#fff',
                          padding: '5px'
                        }}
                      />
                      <CardContent className="item-value">
                        <strong><NumericFormat
                          value={lotto.price}
                          displayType={'text'}
                          thousandSeparator={true}
                        /></strong>
                        <span>$MYCAC</span>
                      </CardContent>
                    </Card>

                    <Card className="item-box">
                      <CardHeader
                        title='End Type'
                        titleTypographyProps={{ className: 'item-label'}}
                        sx={{
                          backgroundColor: '#fff',
                          padding: '5px'
                        }}
                      />
                      <CardContent className="item-value">
                        {lotto.closeType === 'soldout' ? (
                          <div className="item-value-inner"><span><ProductionQuantityLimitsIcon/></span><span>When sold out</span></div>
                        ) : (
                          <div className="item-value-inner"><span><TimerIcon/></span><span>When time up</span></div>
                        )}
                      </CardContent>
                    </Card>
                
                  </Container>

                  
                  <Card className="item-box item-jackpot">
                    <CardHeader
                      title={lotto.closeType === 'soldout' ? (
                          'Jackpot'
                        ) : (
                          'Current Jackpot'
                        )}
                      titleTypographyProps={{ className: 'item-label'}}
                      sx={{
                        backgroundColor: '#fff',
                        padding: '5px'
                      }}
                    />
                    <CardContent className="item-value">
                      <strong>{lotto.closeType === 'soldout' ? (
                        <NumericFormat
                          value={lotto.price*lotto.closeVal2}
                          displayType={'text'}
                          thousandSeparator={true}
                        />
                          
                        ) : (
                          <NumericFormat
                            value={lotto.price*lotto.sold}
                            displayType={'text'}
                            thousandSeparator={true}
                          />
                        )}</strong>
                      <span>$MYCAC</span>
                    </CardContent>
                  </Card>

                  <Box sx={{ display: 'flex', alignItems: 'center', flexDirection: 'column' }}>
                    <Box sx={{ width: '100%', mr: 1, fontSize: '13px' }}>
                      {lotto.closeType === 'soldout' ? (
                          'Tickets Sold'
                        ) : (
                          'Time Remaining'
                        )} 
                    </Box>
                    <Box sx={{ width: '100%', mr: 1 }}>
                      {lotto.closeType === 'soldout' ? (
                          <LinearProgress variant="determinate" value={lotto.closeVal1/lotto.closeVal2*100} />
                        ) :  (
                          <LinearProgress variant="determinate" value={(new Date().getTime() - (lotto.closeVal1*1000)) / ((lotto.closeVal2*1000) - (lotto.closeVal1*1000)) * 100} />
                      )}


                    </Box>
                    <Box sx={{ width: '100%', mr: 1 }}>
                        {lotto.closeType === 'soldout' ? (
                          lotto.closeVal1 + '/' + lotto.closeVal2
                        ) :  (
                            lotto.timeDiff
                        )}
                    </Box>
                  </Box>
                </CardContent>

                <CardActions>
                  <Button fullWidth variant={(lotto.status === 'SOON' || lotto.status === 'FINISHED')  ? (
                      'outlined'
                    ) : (
                      'contained'
                    )} disabled={lotto.status==="SOON"}>

                    {lotto.status === 'OPEN' ? (
                      'Buy Tickets'
                    ) : null}

                    {lotto.status === 'SOON' ? (
                      'Coming Soon'
                    ) : null}

                    {lotto.status === 'FINISHED' ? (
                      'View Winner'
                    ) : null}

                  </Button>
                </CardActions>

              </Card>
            </Grid>
          ))}
        </Grid>
			
		)
	}

}