import * as React from 'react';
import CssBaseline from '@mui/material/CssBaseline';
import Button from '@mui/material/Button';
import Container from '@mui/material/Container';
import Paper from '@mui/material/Paper';
import Grid from '@mui/material/Grid';
import Alert from '@mui/material/Alert';
import {useEffect} from 'react';
import Lottery from './Lottery';

import './App.css';



function App() {
  
  useEffect(() => {
    document.title = 'Cactus Lotto | Cactus on Solana';
  }, []);

  return (
    <div className="App">
      <CssBaseline />

      <Grid container className="actions">
        <Grid>
          <Button variant="contained" href="#contained-buttons">Connect Wallet</Button>
        </Grid>
      </Grid>

      <Container component="main">
        <Paper variant="outlined" sx={{ my: { xs: 3, md: 6 }, p: { xs: 2, md: 3 } }}>
          <Alert severity="info"><strong>BETA MODE</strong> Wallet connection is restricted to approved testing accounts only until public release (soon!)</Alert>

          <p>Welcome to Cactus Lotto. Find all the current lotteries below.</p>
        </Paper>

        <Lottery />

      </Container>
    </div>
  );
}

export default App;
